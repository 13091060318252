import React from 'react'
import { Link, useI18next, Trans } from 'gatsby-plugin-react-i18next';

const Header = () => {
  const { language, originalPath } = useI18next();

  const linkLang = language === 'en' ? 'ru' : 'en'

  return (
    <header className="header">
      <Link to="/" className="logo">
        <Trans>
          Sofiya Bernatskaya
        </Trans>
      </Link>
      <Link to={originalPath} language={linkLang} className="language">
        { linkLang }
      </Link>
    </header>
  )
}

export default Header