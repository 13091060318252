import React, { useEffect, useRef } from "react"
import smokes from 'utils/smokes'

const Background = React.memo(() => {
  const ref = useRef(null)
  
  useEffect(() => {
    smokes(ref.current)
  }, [])

  return (
    <canvas className="canvas" ref={ref}></canvas>
  )
})

export default Background
