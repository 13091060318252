import React from "react"
import { Helmet } from "react-helmet"
import { Trans } from 'gatsby-plugin-react-i18next'
import Background from 'components/background'
import Header from 'components/header'
import Footer from 'components/footer'
import Social from 'components/social'

const links = [
  {
    title: 'Behance',
    url: 'https://www.behance.net/sofi_bernuly',
  }, {
    title: 'Dribbble',
    url: 'https://dribbble.com/sofi_bernuly'
  }, {
    title: 'Instagram',
    url: 'https://www.instagram.com/bern_dsgn/'
  }
]

const Index = () => {
  return (
    <div className="page">
      <Helmet>
        <title>
          Sofiya Bernatskaya
        </title>
        <meta name="viewport" content="width=device-width, initial-scale=1, user-scalable=no, maximum-scale=1.0, minimum-scale=1.0" />
      </Helmet>
      <Background />
      <div className="page__container">
        <Header />
        <div className="page__content">
          <div className="banner">
            <p className="banner__slogan">
              <Trans 
                i18nKey="I will create the right impression of your brand"
              >
                I will create the right <br/> impression of your brand
              </Trans>
            </p>
            <h1 className="banner__title">
              UX/UI designer
            </h1>
            <div className="banner__social">
              <div className="banner__wrapper">
                <p className="banner__social-title">
                  <Trans i18nKey="Have a project idea? I am available for new projects">
                    Have a project idea?<br />I am available for new projects<br/>
                  </Trans>
                  {/* Если у вас есть интересный<br />проект — напишите мне.<br />Готова обсудить любые идеи */}
                </p>
                <p className="banner__social-text">
                  <Trans>
                    You can see my work here:
                  </Trans>
                </p>
                <Social links={links} />
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  )
}

export default Index
