import React from 'react'
import Social from './social'

const links = [
  {
    title: 'Telegram',
    url: 'https://teleg.run/sofi_bernuly',
  }, {
    title: 'E-mail',
    url: 'mailto:sofi@berndsgn.com'
  }
]

const Footer = () => {
  return (
    <footer className="footer">
      <Social links={links} isWide />
    </footer>
  )
}

export default Footer