import React from 'react'

const Social = ({ links, isWide }) => {
  const socialLinks = links.map(({ title, url }) => (
    <li className="social__link" key={title}>
      <a href={url} target="_blank" rel="noreferrer">
        { title }
      </a>
    </li>
  ))

  return (
    <ul className={`social${isWide ? ' social_wide' : ''}`}>
      { socialLinks }
    </ul>
  )
}

export default Social